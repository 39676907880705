<template>
  <div
    v-bind:class="['badge-wrapper', getBadgeClass]"
    v-tooltip.bottom="'Sipariş Notu'"
  >
    {{ getValue }}
  </div>
</template>
<script>
export default {
  name: "TableBadge",
  props: {
    value: {
      default: null,
    },
    badgeClass: {
      default: "badge-primary",
    },
  },
  computed: {
    getValue() {
      let result = this.value;

      if (typeof this.value == "number" && this.value >= 100) {
        result = "99+";
      } else if (this.value == 0) {
        result = "-";
      }

      return result;
    },
    getBadgeClass() {
      return this.value > 0 ? this.badgeClass : "badge-light";
    },
  },
};
</script>
<style lang="scss">
.badge-wrapper {
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 100%;
  font-size: 11px;
}
</style>